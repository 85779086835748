.inventory-item-image{
    height: 4rem;
    width: 4rem;
    background-position: center;
    background-size: cover;
}

tr.table-row{
    border: black 1px solid;

}
tr.table-row:nth-child(2n + 1){
    background-color: #fff6ee;
}