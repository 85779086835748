.header{
  min-height: 10vh;
  margin: 2.5rem 4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

}
.header-logo{
  background-image: url("https://static.wixstatic.com/media/3341df_fda273b991514a438b9fbb08bbc83345~mv2.png/v1/fill/w_2000,h_1000,al_c/3341df_fda273b991514a438b9fbb08bbc83345~mv2.png");
  height: 6.8rem;
  width: 6.8rem;
  background-position: center;
  background-size: cover;
}