@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  
  
  body {
    font-family: 'Barlow', sans-serif !important;
    font-size: 62.5%;
  }
  
  h1 {
    font-size: 2.6rem;
  }
  li,
  button,
  label,
  input,
  p {
    font-size: 1rem;
  }
  p.marg-bottom {
    margin-bottom: 1.2rem;
  }
  h2 {
    font-size: 4.5rem;
  }
  h3 {
    font-size: 2.7rem;
    font-weight: normal;
  }
  h4,
  h5 {
    font-size: 2.5rem;
  }
  a {
    color: black;
    text-decoration: none;
  }
  li {
    list-style: none;
  }

  .folder-container{
    background-color: #d8b791;
    padding: 2rem;
    border: 1px solid black;
    margin: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 5px;

  }
