.form-label{
    font-weight: bold;
    display: block;
    margin-top: 1rem;
}

.confirmation-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    
    p{
        font-size: 2rem;
    }
}



input[type=text], input[type=number], .img-input-box{
    width: 40%;
    font-weight: 200;
}

.img-input-box{
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.568) -1px 1px 7px;
    
}

.sides{
    display: flex;
    .left{
        
        width: 60%;
    }
    .right{
        flex-grow: 1;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        max-width: 20rem;

        .category-title{
            text-align: center;
        }
        
        .nav-btns{
            
            display: flex;
            justify-content: space-between;
        }


        .btn-parent{
            display: inline-block;
            background-color: black;
            border-radius: 5px;

            button{
                color: white;
                background-color: black;
                padding: 0.25rem 1rem;
                min-width: 5rem;
                border: 1px solid white;
                margin:0.5rem;
            }
        }
        
        
    }
}
